var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"width":"70%"},scopedSlots:_vm._u([{key:"default",fn:function({ isActive }){return [_c('v-card',[_c('v-card-title',[_vm._v("Assign Data Target Area - Desa ")]),_c('v-card-text',{staticClass:"ff-email-wrapper"},[_c('ValidationObserver',{ref:"firstForm",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[(_vm.village_data)?_c('v-row',{staticClass:"assign-ff-wrapper"},[_c('v-col',{staticClass:"form-separator",attrs:{"lg":"12"}},[_c('div',{staticClass:"d-flex flex-row align-items-center"},[_c('p',{staticClass:"mb-0"},[_vm._v("Pemetaan TA - Desa")]),_c('v-btn',{staticClass:"add-button ml-2",attrs:{"variant":"success","small":""},on:{"click":function($event){return _vm.addTaDesa()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")])],1)],1)]),_vm._l((_vm.ta_desa),function(taDesa,i){return _c('v-col',{key:'taDesa-' + i,attrs:{"lg":"12"}},[_c('v-row',{staticClass:"mx-3 bg-grey"},[_c('v-col',{attrs:{"lg":"6"}},[_c('geko-input',{attrs:{"item":{
                        label: 'Tahun Program',
                        type: 'select',
                        validation: ['required'],
                        option: {
                          default_label: taDesa.program_year,
                          multiple: true,
                          default_options: [
                            {
                              label: '2020',
                              code: '2020',
                            },
                            {
                              label: '2021',
                              code: '2021',
                            },
                            {
                              label: '2022',
                              code: '2022',
                            },
                            {
                              label: '2023',
                              code: '2023',
                            },
                            {
                              label: '2024',
                              code: '2024',
                            },
                          ],
                          list_pointer: {
                            label: 'label',
                            code: 'code',
                            display: ['label'],
                          },
                        },
                      }},model:{value:(taDesa.program_year),callback:function ($$v) {_vm.$set(taDesa, "program_year", $$v)},expression:"taDesa.program_year"}})],1),_c('v-col',{attrs:{"lg":"5"}},[_c('geko-input',{attrs:{"item":{
                        type: 'select',
                        label: 'Target Area',
                        validation: ['required'],
                        api: 'new-utilities/target-areas',
                        default_label: taDesa.target_areas_name,
                        param: {
                          active: 1,
                          limit: 1000,
                          offset: 0
                        },
                        option: {
                          list_pointer: {
                            code: 'area_code',
                            label: 'name',
                            display: ['name'],
                          },
                        },
                      }},model:{value:(taDesa.area_code),callback:function ($$v) {_vm.$set(taDesa, "area_code", $$v)},expression:"taDesa.area_code"}})],1),_c('v-col',{staticClass:"mt-0 pt-0 d-flex flex-column",staticStyle:{"justify-content":"center","position":"relative","transform":"translateY(15%)"},attrs:{"lg":"1"}},[_c('v-btn',{staticClass:"btn-icon",attrs:{"small":"","variant":"danger"},on:{"click":function($event){return _vm.removeTADesa(i)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete-empty")])],1)],1)],1)],1)}),_c('v-col',{attrs:{"lg":"12"}},[(_vm.error_ta_desa)?_c('p',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.error_ta_desa)+" ")]):_vm._e()])],2):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"d-flex flex-row justify-content-center",attrs:{"lg":"12"}},[_c('v-btn',{staticClass:"d-flex flex-row align-items-center",attrs:{"variant":"warning","type":"submit"}},[_c('v-icon',[_vm._v("mdi-pencil-outline")]),_c('span',[_vm._v("Perbarui Data Desa")])],1)],1)],1),_c('v-row')],1)]}}],null,true)})],1)],1)]}}]),model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}})
}
var staticRenderFns = []

export { render, staticRenderFns }